import { IDRepFormField, ReferenceWithId } from "@src/models/dtos/metadata";
import LinkFieldManager from "@src/components/molecules/linkFieldManager";
import { uuid } from "uuidv4";
import { IError } from "@src/models/dtos";

export default function StepFour({
  setDRepRegistrationFormData,
  dRepRegistrationFormData,
  error,
  setError,
}: {
  setDRepRegistrationFormData: (
    value: (prev: IDRepFormField) => IDRepFormField,
  ) => void;
  dRepRegistrationFormData: IDRepFormField;
  setError: (value: IError | undefined) => void;
  error: IError | undefined;
}) {
  const IdentityLink = dRepRegistrationFormData.references.filter(
    (reference) => reference["@type"] !== "Link",
  );
  const links: Array<ReferenceWithId> =
    IdentityLink.length === 0
      ? [
          {
            id: uuid(),
            "@type": "Identity",
            label: "",
            uri: "",
          },
        ]
      : IdentityLink;
  return (
    <LinkFieldManager
      error={error}
      setError={setError}
      type="Identity"
      setDRepRegistrationFormData={setDRepRegistrationFormData}
      label="Identity"
      links={links}
    />
  );
}
