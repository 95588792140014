import DRepIDTextArea from "@src/components/atoms/textArea";
import { IDRepFormField } from "@src/models/dtos/metadata";
import { ChangeEvent } from "react";

export default function StepFirst({
  setDRepRegistrationFormData,
  dRepRegistrationFormData,
}: {
  setDRepRegistrationFormData: (
    value: (prev: IDRepFormField) => IDRepFormField,
  ) => void;
  dRepRegistrationFormData: IDRepFormField;
}) {
  const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setDRepRegistrationFormData((prevState: IDRepFormField) => ({
      ...prevState,
      [id]: value,
    }));
  };
  return (
    <div className="flex flex-col gap-6 w-full">
      <DRepIDTextArea
        inputCharacter={dRepRegistrationFormData.objectives?.length || 0}
        id="objectives"
        label="Objectives"
        value={dRepRegistrationFormData.objectives}
        onChange={handleOnChange}
        placeholder="Enter your objectives"
      />
      <DRepIDTextArea
        inputCharacter={dRepRegistrationFormData.motivations?.length || 0}
        id="motivations"
        label="Motivations"
        value={dRepRegistrationFormData.motivations}
        onChange={handleOnChange}
        placeholder="Enter your motivations"
      />
      <DRepIDTextArea
        inputCharacter={dRepRegistrationFormData.qualifications?.length || 0}
        id="qualifications"
        value={dRepRegistrationFormData.qualifications}
        label="Qualifications"
        onChange={handleOnChange}
        placeholder="Enter your qualifications"
      />
    </div>
  );
}
