import { combineReducers, configureStore, Reducer } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { persistStore, persistReducer } from "redux-persist";
import { RESET_STATE_ACTION_TYPE } from "@src/store/actions/resetState";
import { drepApi } from "@src/store/drep/api";
import { metadataApi } from "./metadata/api";
import { transactionApi } from "./transaction/api";
import persistedPendingtransactionReducer from "./transaction/transaction";
import storage from "redux-persist/lib/storage";
import { walletSlice } from "./user/wallet";
import { searchApi } from "./search/api";

const middlewares = [
  drepApi.middleware,
  metadataApi.middleware,
  transactionApi.middleware,
  searchApi.middleware,
];

const walletPersistConfig = {
  key: "wallet",
  storage,
  whitelist: ["name", "icon", "instance"],
};

const persistedWalletReducer = persistReducer(
  walletPersistConfig,
  walletSlice.reducer,
);

const reducers = {
  [drepApi.reducerPath]: drepApi.reducer,
  wallet: persistedWalletReducer,
  [metadataApi.reducerPath]: metadataApi.reducer,
  [transactionApi.reducerPath]: transactionApi.reducer,
  pendingTransactions: persistedPendingtransactionReducer,
  [searchApi.reducerPath]: searchApi.reducer,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;

setupListeners(store.dispatch);
